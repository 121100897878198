import ThemisInput from "@/components/shared/input"
import ThemisDecision from "@/components/shared/decision"
import { mapActions, mapGetters } from "vuex"
import { STATUS_KEYS } from "@/constants"
import { FIELD_TYPES } from "@/constants"

export default {
  name      : "Screen-Side-Panel",
  components: {
    ThemisInput,
    ThemisDecision
  },
  data: () => ({
    showRemoveScreenItemDialog: false,
    localScreenItem           : undefined
  }),
  computed: {
    ...mapGetters({
      screenItems         : "screens/screenItems",
      screens             : "screens/screens",
      fieldsV2            : "fields/fieldsV2",
      optionLists         : "optionLists/optionLists",
      isMandatoryUpdated  : "screenItems/isMandatoryUpdated",
      isScreenItemRemoved : "screenItems/isScreenItemRemoved",
      isRemovingScreenItem: "screenItems/isRemovingScreenItem",
      isUpdatingMandatory : "screenItems/isUpdatingMandatory"
    }),
    screen() {
      return this.screens.find(screen => screen.id === +this.$route.params.id)
    },
    screenItem() {
      return this.screenItems.find(item => item.id === +this.$route.params.itemId)
    },
    screenItemKey() {
      return this.screenItem?.key
    },
    screenItemFieldId() {
      return this.screenItem?.fields?.find(field => field.id)?.id
    },
    fieldsMap() {
      const fieldsMap = new Map()
      for (const field of this.fieldsV2) {
        fieldsMap.set(field.id, field)
      }
      return fieldsMap
    },
    optionListsMap() {
      const optionListsMap = new Map()
      for (const optionList of this.optionLists) {
        optionListsMap[optionList.id] = optionList
      }
      return optionListsMap
    },
    isIssueField() {
      return this.screenItemKey === STATUS_KEYS.issueField
    },
    itemName() {
      return this.isIssueField
        ? this.fieldsMap.get(this.screenItemFieldId)?.systemName
        : this.$t(STATUS_KEYS[this.screenItemKey])
    },
    selectedField() {
      return this.fieldsMap.get(this.screenItemFieldId)
    },
    selectedFieldType() {
      if (this.selectedField) {
        return Object.values(FIELD_TYPES).find(fieldType => fieldType.value === this.selectedField.type)
      }
    },
    optionListNameOfSelectedField() {
      return this.optionListsMap[this.selectedField.optionListId]?.name
    },
    fieldTypeIconOfSelectedField() {
      return this.selectedFieldType?.icon
    },
    fieldTypeNameOfSelectedField() {
      return this.selectedFieldType?.name
    },
    showOptionListDetails() {
      return [FIELD_TYPES.OPTION_LIST.value, FIELD_TYPES.MULTIPLE_OPTION_LIST.value].includes(
        this.selectedField?.type
      )
    }
  },
  methods: {
    ...mapActions({
      updateScreenItem: "screenItems/updateScreenItem",
      removeScreenItem: "screenItems/removeScreenItem",
      notify          : "shared/notify"
    }),
    handleRemoveScreenItem() {
      this.removeScreenItem(this.screenItem.id)
    }
  },
  watch: {
    screenItem: {
      immediate: true,
      handler  : function(newValue) {
        if (newValue) {
          const isLocalScreenItemNotSet          = !this.localScreenItem
          const isLocalScreenItemSetButDifferent = !isLocalScreenItemNotSet &&
            this.localScreenItem.id !== newValue.id
          if (isLocalScreenItemNotSet || isLocalScreenItemSetButDifferent) {
            this.localScreenItem = { ...newValue }
          }
        }
      }
    },
    isMandatoryUpdated: {
      handler: function(value) {
        if (value) {
          this.notify({
            type: "success",
            text: "1529"
          })
        }
      }
    },
    isScreenItemRemoved: {
      handler: function(newValue) {
        if (newValue) {
          this.$router.push({ name: "screen", params: { id: this.screen.id } })
          this.notify({
            type: "success",
            text: "1529"
          })
        }
      }
    },
    isRemovingScreenItem: {
      immediate: false,
      handler  : function(newValue) {
        this.$DECISIONS.REMOVE_SCREEN_ITEM.pActions[0].buttonProps.disabled = newValue
        this.$DECISIONS.REMOVE_SCREEN_ITEM.pActions[1].buttonProps.loading  = newValue
      }
    }
  }
}